import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Homemade Pizza Cheat Sheet",
  "date": "2020-04-05T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1100px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "138.9090909090909%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUBAgME/8QAFQEBAQAAAAAAAAAAAAAAAAAAAgH/2gAMAwEAAhADEAAAAVs2Yh3MRHi3WRK8OYN//8QAHBAAAgICAwAAAAAAAAAAAAAAAQIAEQMQEiEx/9oACAEBAAEFAsfgIcA9NRe5cHCKUJut4mLJ/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BiKj/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwGqiv/EAB0QAAEEAgMAAAAAAAAAAAAAACEAAQIREBIgUYH/2gAIAQEABj8CD6uqcS7w1DAvxXshLgV//8QAHRAAAwACAgMAAAAAAAAAAAAAAAERIUEQMVFhkf/aAAgBAQABPyGW1fKhg1no5wOgMJhHTTG10/JN4aS1Ga+hDEFtWf/aAAwDAQACAAMAAAAQKx09/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERITH/2gAIAQMBAT8QvhYmRrT/xAAXEQEBAQEAAAAAAAAAAAAAAAABACER/9oACAECAQE/EEkGerGX/8QAHRABAQACAwADAAAAAAAAAAAAAREAITFBYVGRof/aAAgBAQABPxDnBZM2e4ooyEmldnT7jRS+nGSaMDObASUJql+8BBA6iPzEZEOksycK9K783kEJAaAxt5zR11xkhwpfmZ//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "pizza",
          "title": "pizza",
          "src": "/static/2cde93de120f1d862e5a2490cdef16fb/ddced/pizza.jpg",
          "srcSet": ["/static/2cde93de120f1d862e5a2490cdef16fb/35f54/pizza.jpg 275w", "/static/2cde93de120f1d862e5a2490cdef16fb/d7854/pizza.jpg 550w", "/static/2cde93de120f1d862e5a2490cdef16fb/ddced/pizza.jpg 1100w", "/static/2cde93de120f1d862e5a2490cdef16fb/e5166/pizza.jpg 1200w"],
          "sizes": "(max-width: 1100px) 100vw, 1100px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`I’d like to preface this article by saying I’m by no means an expert on making your own pizza. I just recently started dabbling in making my own dough and these were the tips that helped me the most. I’m both writing this for some friends who asked for my tips, and for past me (to make sure I don’t forget).`}</p>
    <h3>{`Making the Dough`}</h3>
    <h3>{`Can be done up to 2 days ahead`}</h3>
    <p><strong parentName="p">{`Note:`}</strong>{` My recipe is an adapted recipe from `}<a parentName="p" {...{
        "href": "https://www.bonappetit.com/recipe/pizza-dough-2"
      }}>{`Bon Appetit`}</a>{`.`}</p>
    <h4>{`Secrets:`}</h4>
    <ul>
      <li parentName="ul">{`The best pizza dough is in the 70-80% hydration range. Hydration just means the water to flour ratio. `}<a parentName="li" {...{
          "href": "https://pizzaotherbread.wordpress.com/2016/02/28/pizza-dough-hydration-trial/"
        }}>{`Learn more`}</a></li>
      <li parentName="ul">{`While you prepare the dough, your hands and even the countertop can suck the moisture out of your dough. Make sure you spray the countertops and your hands frequently. `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=xREpnr1Frf8"
        }}>{`Learn more`}</a></li>
    </ul>
    <h4>{`Ingredients:`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`177g`}</strong>{` warm water (105°F to 115°F)`}</li>
      <li parentName="ul"><strong parentName="li">{`240g`}</strong>{` flour (ideally `}<a parentName="li" {...{
          "href": "https://www.amazon.ca/gp/product/B0038ZS6PU"
        }}>{`Typo 00 Flour`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`8g`}</strong>{` active dry yeast`}</li>
      <li parentName="ul"><strong parentName="li">{`1 teaspoon`}</strong>{` sugar`}</li>
      <li parentName="ul"><strong parentName="li">{`¾ teaspoon`}</strong>{` salt`}</li>
    </ul>
    <h4>{`Steps:`}</h4>
    <ol>
      <li parentName="ol">{`Pour `}<strong parentName="li">{`177g warm water`}</strong>{` into small bowl; stir in `}<strong parentName="li">{`8g of yeast`}</strong>{`. Let stand until yeast dissolves, about `}<strong parentName="li">{`5 minutes`}</strong>{`.`}</li>
      <li parentName="ol">{`In a separate bowl, mix `}<strong parentName="li">{`240g flour`}</strong>{`, `}<strong parentName="li">{`1 teaspoon sugar`}</strong>{`, and `}<strong parentName="li">{`1 teaspoon salt`}</strong>{`.`}</li>
      <li parentName="ol">{`Add yeast mixture and mix until the dough forms in a sticky ball (use your hands to form it).`}</li>
      <li parentName="ol">{`Knead dough. `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=xREpnr1Frf8"
        }}>{`Follow what they do in this video`}</a></li>
      <li parentName="ol">{`Let the dough rise in an airtight container for `}<strong parentName="li">{`30 mins`}</strong>{`.`}</li>
      <li parentName="ol">{`Separate the dough in 2 balls.`}</li>
      <li parentName="ol">{`Done! (If you’re doing this ahead of time, put your dough balls in the fridge in airtight containers.)`}</li>
    </ol>
    <hr></hr>
    <h3>{`Preparing the pizza`}</h3>
    <p>{`⚠️ Make sure you preheat your oven first (see below)`}</p>
    <h4>{`Secrets:`}</h4>
    <ul>
      <li parentName="ul">{`Never use a rolling pin. It will destroy the bubbles that we want in the crust. The best technique is to use gravity to help you by lifting the dough.`}</li>
      <li parentName="ul">{`The dough smells fear. So when you transfer your pizza from the peel to the stone, don’t hesitate just go for it.`}</li>
    </ul>
    <h4>{`Steps:`}</h4>
    <ol {...{
      "start": 0
    }}>
      <li parentName="ol">{`Take your dough ball out at least an hour before you prepare your pizza to give it time to warm up to room temperature.`}</li>
      <li parentName="ol">{`Dunk your dough balls in a bowl of flour (so that the bottom of your ball has flour on it).`}</li>
      <li parentName="ol">{`Expand the dough by lifting and turning it in the air.`}</li>
    </ol>
    <hr></hr>
    <h3>{`Baking the pizza`}</h3>
    <h4>{`Secrets:`}</h4>
    <ul>
      <li parentName="ul">{`You want your oven to be as hot as possible. Unfortunately, regular home ovens don’t get that hot, so we’ll use the bake/broil technique to get as close as possible. `}<a parentName="li" {...{
          "href": "https://pizzaotherbread.wordpress.com/2015/02/20/how-to-bake-pizza/"
        }}>{`Lean more about this technique`}</a>{`.`}</li>
    </ul>
    <h4>{`Materials:`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://thewirecutter.com/reviews/best-pizza-stone-and-baking-steel/"
        }}>{`Pizza Stone`}</a></li>
      <li parentName="ul">{`Pizza Peel`}</li>
    </ul>
    <h4>{`Steps:`}</h4>
    <ol>
      <li parentName="ol">{`Set the grill to the highest position.`}</li>
      <li parentName="ol">{`Put your stone in the oven.`}</li>
      <li parentName="ol">{`Preheat your over to the `}<strong parentName="li">{`maximum temperature`}</strong>{` it will go for at least `}<strong parentName="li">{`1 hour`}</strong>{` before you bake.`}</li>
      <li parentName="ol"><strong parentName="li">{`5 minutes before`}</strong>{` you bake your pizza, set the oven to `}<strong parentName="li">{`broil`}</strong>{`.`}</li>
      <li parentName="ol">{`Right before you put your pizza in the oven, set it back to `}<strong parentName="li">{`bake`}</strong>{`.`}</li>
      <li parentName="ol">{`Bake for `}<strong parentName="li">{`4 minutes`}</strong>{`.`}</li>
      <li parentName="ol">{`Switch your oven to `}<strong parentName="li">{`broil`}</strong>{` again for `}<strong parentName="li">{`2-3 minutes`}</strong>{`.`}</li>
      <li parentName="ol">{`Done!`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      